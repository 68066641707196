import React from "react";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { ConfirmationCodeProps } from "./types";

const ConfirmationCode = ({ confirmationCode, updateConfirmationCode }: ConfirmationCodeProps) => {
    const { Control } = Form;
    const { Feedback } = Control;
    return (
        <InputGroup>
            <FloatingLabel label="Recovery code" className="mb-3">
                <Control
                    data-testid="code"
                    type="text"
                    placeholder="N/A"
                    value={confirmationCode}
                    onChange={({ target: { value } }) => updateConfirmationCode(value)}
                    required
                />
                <Feedback type="invalid" tooltip>
                    Please enter a valid email address.
                </Feedback>
            </FloatingLabel>
        </InputGroup>
    );
};

export { ConfirmationCode };
