import { Form } from "react-bootstrap"

const SignpostModalItem = ({item, prop, onChange, label, readonly = false}:any) => {
return (
    <Form.Group className="mb-3" controlId={prop}>
  <Form.Label>{label}</Form.Label>
  <Form.Control type="text"  value={item[prop] || ""} onChange={onChange} readOnly={readonly}/>
</Form.Group>
)
}

export { SignpostModalItem }