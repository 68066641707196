import { CognitoIdentityServiceProvider } from "aws-sdk";
import React, { createContext, useContext, useState } from "react";
import { useConfigContext } from "./config-context";
import { AuthContextType, UserAuthenticated } from "./types";

const AuthContext = createContext<AuthContextType>({
    cognitoServiceProvider: new CognitoIdentityServiceProvider(),
    authStatus: UserAuthenticated.UNKNOWN,
    tokenStore: { accessToken: "", idToken: "", refreshToken: "" },
    email: "",
    updateEmail: () => {},
    updateAuthStatus: () => {},
    updateTokenStore: () => {},
    updateRefreshTokenCookie: (_: string) => {},
});

const AuthProvider = ({ children }: { children: any }) => {
    const {
        auth: { region },
    } = useConfigContext();

    const [authStatus, updateAuthStatus] = useState(UserAuthenticated.UNKNOWN);
    const [tokenStore, updateTokenStore] = useState({ accessToken: "", idToken: "", refreshToken: "" });
    const [email, updateEmail] = useState<string>("");

    const updateRefreshTokenCookie = (refreshToken: string) => {
        function setCookie(name: string, value: string, options: { [key: string]: string | boolean | Date } = {}) {
            const cookieOptions = Object.entries(options)
                .map(([key, val]) => `${key}=${val}`)
                .join("; ");
            document.cookie = `${name}=${value}; ${cookieOptions}`;
        }

        setCookie("refreshToken", refreshToken, {
            path: "/", // Set the path for the cookie
            expires: new Date(Date.now() + 24 * 60 * 60 * 1000), // expire in 24 hours
            secure: true,
            sameSite: "None", // Adjust based on your requirements, e.g., 'None', 'Lax', 'Strict'
        });
    };

    const cognitoServiceProvider = new CognitoIdentityServiceProvider({
        region,
    });
    return (
        <AuthContext.Provider
            value={{
                cognitoServiceProvider,
                authStatus,
                updateAuthStatus,
                tokenStore,
                updateTokenStore,
                email,
                updateEmail,
                updateRefreshTokenCookie,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

const useAuthContext = () => useContext(AuthContext);

export { AuthContext, AuthProvider, useAuthContext };
