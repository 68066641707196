/// PROD
const prodConfig = {
    apiBaseUrl: "https://l30sqsynej.execute-api.eu-west-1.amazonaws.com/prod",
    defaultLandingPage: "login",
    auth:{
        region: "eu-west-1",
        clientId : "7iaksrebjurhkgnok12avoao0k",
        flow: "USER_PASSWORD_AUTH"
    },
    features: {
        inlineEdit: false
    }
}

// DEV
// const prodConfig = {
//     apiBaseUrl: "https://h6hz0srzt5.execute-api.eu-west-1.amazonaws.com/prod",
//     defaultLandingPage: "login",
//     auth:{
//         region: "eu-west-1",
//         clientId : "1f2o1fhm0hqktg0erod7rae8dd",
//         flow: "USER_PASSWORD_AUTH"
//     },
//     features: {
//         inlineEdit: false
//     }
// }

const localConfig = {
    apiBaseUrl: "http://localhost:5001",
    defaultLandingPage: "login",
    auth:{
        region: "eu-west-1",
        clientId : "1f2o1fhm0hqktg0erod7rae8dd",
        flow: "USER_PASSWORD_AUTH"
    },
    features: {
        inlineEdit: false
    }
}

const config = process.env.REACT_APP_IS_LOCAL ? localConfig : prodConfig;

export { config }