import React from "react";
import { Button } from "react-bootstrap";
import { useAuthentication } from "./useAuthentication";

const LogOut = () => {
    const { logout } = useAuthentication();

    const handleSubmit = () => {
        logout();
    };

    return (
        <>
            <Button onClick={handleSubmit} variant="link" className="mb-1">
                Logout
            </Button>
        </>
    );
};

export { LogOut };
