import React, { FormEvent, useState } from "react";
import { Form } from "react-bootstrap";
import { Email } from "./Email";
import { Submit } from "./Submit";

const Recover = ({
    email,
    updateEmail,
    onSubmit,
}: {
    email: string;
    updateEmail: React.Dispatch<React.SetStateAction<string>>;
    onSubmit: () => void;
}) => {
    const [validated, setValidated] = useState(false);

    const message = "Please enter your email address to recover your account.";

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;

        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity()) {
            onSubmit();
        }
        setValidated(true);
    };
    return (
        <>
            <p>{message}</p>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Email email={email} updateEmail={updateEmail} />
                <Submit label="Send Recovery Email" />
            </Form>
        </>
    );
};

export { Recover };
