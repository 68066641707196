import React, { useEffect, useState } from 'react';
import Fuse from 'fuse.js';
import { Button, Col, Form, Row, Spinner, Table } from 'react-bootstrap';
import { bool } from 'aws-sdk/clients/signer';
import { SignpostModal } from './SignpostModal';
import { SignpostRowItem } from './SignpostRowItem';
import { useConfigContext } from '../context/config-context';
import { UpdateOperation } from './types';

interface Item {
  id: number;
  condition: string;
  note: string;
  aware: string;
  signpost: string;
  appointment: string;
  signpost_2: string;
  appointment_2: string;
  information_source: string;
  information_url: string;
  deleted: boolean;
}

interface Props {
  data: Item[];
  isAdmin: bool;
  saveSignpostData: any;
}

const Signpost: React.FC<Props> = ({ data, isAdmin, saveSignpostData }) => {
  const [showDeleted, updateShowDeleted] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState<Item[]>([]);
  const [selectedItem, updateSelectedItem] = useState<any>({});
  const [showModal, setShowModal] = useState(false);
  const [editRow, setEditRow] = useState(-1);
  const { features: { inlineEdit } } = useConfigContext();

  useEffect(() => {
    const fuse = new Fuse(showDeleted && isAdmin ? data : data.filter(item => !item.deleted), {
    keys: ['condition', 'note', 'aware', 'signpost', 'signpost_2'],
    threshold: 0.3, // Adjust the threshold for fuzzy matching
  });
    if(isAdmin && !searchTerm)
      setResults(data.filter(item => !item.deleted))
    else
      setResults(fuse.search(searchTerm).map((result) => result.item))
  }, [isAdmin, data, searchTerm, showDeleted])

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleEditItemClick = (id: number) => {
      if(!inlineEdit){
        updateSelectedItem(results.find(x => x.id === id))
        setShowModal(true);
      }
      else{
        setEditRow(id);
      }
  }

  const handleNewItemClick = () => {
    const maxId = data.reduce((previous, current) => previous.id > current.id ? previous : current).id
    updateSelectedItem({id: maxId+1})
    setShowModal(true)
  }

  const handleModalClose = ({updateOperation, itemToUpdate, isNew}: {updateOperation: UpdateOperation, itemToUpdate: any, isNew: boolean}) => {
    let newData;
    if(updateOperation === UpdateOperation.SAVE) {
      if(isNew){
        data.push(itemToUpdate)
        newData = data;
      }
        else {
        newData = data.map(item => item.id !== itemToUpdate.id ? item : itemToUpdate)
      }
      saveSignpostData(newData)
    }
    else if (updateOperation === UpdateOperation.DELETE){
      const newData = data.map(item => item.id === itemToUpdate.id ? {...item, deleted: !item.deleted} : item)
      saveSignpostData(newData)
    }
    setShowModal(false);
  }

  const handleShowDeleted = () => updateShowDeleted(!showDeleted)

  return (
    <Form>
       <Row>
        <Col>{isAdmin && <Form.Check type="switch" label="Show Deleted Items" reverse onChange={handleShowDeleted}></Form.Check>}</Col>
        {data.length > 0 ? (<Col>
            <Form.Control
        type="text"
        placeholder="Search..."
        value={searchTerm}
        id="search"
        aria-describedby="search"
        onChange={handleSearch}
      />
      { !searchTerm && <Form.Text id="search">Start typing a keyword for your enquiry</Form.Text> }
      <br/>
      </Col>) : <Spinner/>}
        <Col>{isAdmin && <Button onClick={handleNewItemClick}>Add new condition</Button>}</Col>
      </Row>

      {( searchTerm || isAdmin ) && <Table striped bordered hover>
      <thead>
        <tr>
          <th>Condition</th>
          <th>Note</th>
          <th>Be Aware</th>
          <th>Signpost</th>
          <th>Appt Length</th>
          <th>2nd Level Signpost</th>
          <th>Appt Length</th>
          <th>Information</th>
        </tr>
      </thead>
      <tbody>
      {results.map(({
        id, condition, note, aware, signpost,
        appointment, signpost_2, appointment_2,
        information_source, information_url
      }) => {
        return(
            <tr key={id} onClick={isAdmin ? () => handleEditItemClick(id) : () => {}}>
              <SignpostRowItem editable={id === editRow} value={condition} placeholder="Condition" />
              <SignpostRowItem editable={id === editRow} value={note} placeholder="Note" />
              <SignpostRowItem editable={id === editRow} value={aware} placeholder="Be Aware" />
              <SignpostRowItem editable={id === editRow} value={signpost} placeholder="Aignpost" />
              <SignpostRowItem editable={id === editRow} value={appointment} placeholder="Appt Length" />
              <SignpostRowItem editable={id === editRow} value={signpost_2} placeholder="2nd Level Signpost" />
              <SignpostRowItem editable={id === editRow} value={appointment_2} placeholder="Appt Length" />
              <td> { id===editRow ?
                <>
                <input type="text" value={information_url} placeholder="URL"/>
                <input type="text" value={information_source} placeholder="Link Text" />
                </> : information_url && <a href={information_url} target="_blank" rel="noreferrer">{information_source}</a>
              }
              </td>
            </tr>
        )}
        )}
      </tbody>
    </Table>}
        <SignpostModal
        showModal={showModal}
        handleModalClose={handleModalClose}
        selectedItem={selectedItem}
        />
    </Form>
  );
};

export default Signpost;