import React, { FormEvent, useState } from "react";
import { Form } from "react-bootstrap";
import { Submit } from "./Submit";
import { Password } from "./Password";
import { ResetProps } from "./types";
import { ConfirmationCode } from "./ConfirmationCode";

const Reset = ({ onSubmit }: { onSubmit: (props: ResetProps) => void }) => {
    const [validated, setValidated] = useState(false);
    const [password, updatePassword] = useState<string>("");
    const [confirmationCode, updateConfirmationCode] = useState<string>("");

    const message = "Please enter your recovery code and choose a new password.";

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;

        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity()) {
            onSubmit({ password, confirmationCode });
        }
        setValidated(true);
    };
    return (
        <>
            <p>{message}</p>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <ConfirmationCode confirmationCode={confirmationCode} updateConfirmationCode={updateConfirmationCode} />
                <Password password={password} updatePassword={updatePassword} label="New Password" />
                <Submit label="Reset Password" />
            </Form>
        </>
    );
};

export { Reset };
