import React from "react";
import { Button, Row } from "react-bootstrap";
import { AuthenticationOptionsProps } from "./types";

const AuthenticationOptions = ({ updateWorkflow }: AuthenticationOptionsProps) => (
    <Row className="m-1">
        <br />
            <Button data-testid="forgot" variant="link" className="mb-1" onClick={() => updateWorkflow("recover")}>
                Forgot Password
            </Button>
    </Row>
);

export { AuthenticationOptions };
