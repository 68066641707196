import { useConfigContext } from "../context/config-context";

const SignpostRowItem = ({editable, value, placeholder}:{editable: boolean, value: string, placeholder: string}) => {
    const {features:{inlineEdit}} = useConfigContext();
return (
    <td>{editable && inlineEdit ? <input type="textarea" value={value} placeholder={placeholder} /> : value}</td>

)
}

export { SignpostRowItem }