import { Button, Form, Modal } from "react-bootstrap"
import { SignpostModalItem } from "./SignpostModalItem";
import { useEffect, useState } from "react";
import { UpdateOperation } from "./types";

const SignpostModal = ({
    showModal,
    handleModalClose,
    selectedItem
}: {
    showModal:boolean,
    handleModalClose: any,
    selectedItem:any}) => {

      const [modalData, updateModalData] = useState({});
      const [isNew, updateIsNew] = useState(false);

      useEffect(()=>{
        updateIsNew(!selectedItem.condition)
        updateModalData(selectedItem)
      }, [selectedItem])

  const handleChange = ({target}: any) => {
    updateModalData({
      ...modalData,
      [target.id]: target.value
    })
  }
  const handleSave = () => {
    handleModalClose({isNew, updateOperation: UpdateOperation.SAVE, itemToUpdate: modalData})
  };

  const handleDelete = () => {
    updateModalData(selectedItem);
    handleModalClose({updateOperation: UpdateOperation.DELETE ,itemToUpdate: modalData});
  }

  const handleClose = () => {
    updateModalData(selectedItem);
    handleModalClose({updateOperation: UpdateOperation.NONE});
  }

    return (
<Modal
show={showModal}
onHide={handleClose}
backdrop="static"
aria-labelledby="contained-modal-title-vcenter"
centered
>
<Modal.Header closeButton>
    <Modal.Title>{isNew ? "Create new" : "Update"} condition</Modal.Title>
  </Modal.Header>
  <Modal.Body>
  <Form>
    <SignpostModalItem item={modalData} prop={"deleted"} onChange={handleChange} label="Deleted" readonly={true} />
    <SignpostModalItem item={modalData} prop={"condition"} onChange={handleChange} label="Condition"  />
    <SignpostModalItem item={modalData} prop={"note"} onChange={handleChange} label="Note"  />
    <SignpostModalItem item={modalData} prop={"aware"} onChange={handleChange} label="Be Aware"  />
    <SignpostModalItem item={modalData} prop={"signpost"} onChange={handleChange} label="Signpost"  />
    <SignpostModalItem item={modalData} prop={"appointment"} onChange={handleChange} label="Appt Length"  />
    <SignpostModalItem item={modalData} prop={"signpost_2"} onChange={handleChange} label="2nd Level Signpost"  />
    <SignpostModalItem item={modalData} prop={"appointment_2"} onChange={handleChange} label="Appt Length"  />
    <SignpostModalItem item={modalData} prop={"information_source"} onChange={handleChange} label="Information (Text)"  />
    <SignpostModalItem item={modalData} prop={"information_url"} onChange={handleChange} label="Information (URL)"  />
</Form>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handleClose}>
      Close
    </Button>
    {!isNew  && <Button variant="danger" onClick={handleDelete}>
      { selectedItem.deleted ? "Undelete" : "Delete" }
    </Button>}
    <Button variant="primary" onClick={handleSave}>
      Save changes
    </Button>

  </Modal.Footer>
</Modal>
    )
}

export { SignpostModal }