import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ConfigProvider } from './context/config-context';
import { AuthProvider } from './context/auth-context';

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
      <ConfigProvider>
          <AuthProvider>
              <App />
          </AuthProvider>
      </ConfigProvider>
  </React.StrictMode>,
);