import React from "react";
import { Button, Row } from "react-bootstrap";

const Submit = ({ label = "Submit" }: { label?: string }) => (
    <Row>
        <Button data-testid="submit" variant="success" type="submit" className="m-1 btn-lg">
            {label}
        </Button>
    </Row>
);

export { Submit };
