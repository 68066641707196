import React from "react";
import { config } from "../config";
import { Config } from "./types";

const ConfigContext = React.createContext<Config>(config);

const ConfigProvider = ({ children }: { children: any }) => (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
);

const useConfigContext = () => React.useContext(ConfigContext);

export { ConfigContext, ConfigProvider, useConfigContext };
