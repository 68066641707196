import React, { FormEvent, useState } from "react";
import { Form, Row } from "react-bootstrap";
import { Email } from "./Email";
import { Password } from "./Password";
import { Submit } from "./Submit";
import { LoginProps } from "./types";

const Login = ({
    email,
    isEmailDisabled = false,
    updateEmail,
    onSubmit,
    message = "Please enter your email address and password to login.",
}: {
    email: string;
    isEmailDisabled?: boolean;
    updateEmail: React.Dispatch<React.SetStateAction<string>>;
    onSubmit: (props: LoginProps) => void;
    message?: string;
}) => {
    const [validated, setValidated] = useState(false);
    const [password, updatePassword] = useState<string>("");

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;

        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity()) {
            onSubmit({ email, password });
        }
        setValidated(true);
    };

    return (
        <Row className="m-1">
            <p>{message}</p>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Email email={email} disabled={isEmailDisabled} updateEmail={updateEmail} />
                <Password password={password} updatePassword={updatePassword} />
                <Submit label="Login" />
            </Form>
        </Row>
    );
};

export { Login };
