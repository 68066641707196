import React, { useState } from "react";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { PasswordProps } from "./types";

const Password = ({ password, updatePassword, label }: PasswordProps) => {
    const { Control } = Form;
    const { Feedback } = Control;
    const { Text } = InputGroup;

    const [passwordVisible, updatePasswordVisible] = useState<boolean>(false);

    const togglePasswordVisibility = () => updatePasswordVisible(!passwordVisible);

    return (
        <InputGroup>
            <FloatingLabel label={label || "Password"} className="mb-3 form-floating-group">
                <Control
                    id="test"
                    data-testid="password"
                    type={passwordVisible ? "text" : "password"}
                    placeholder="N/A"
                    value={password}
                    onChange={({ target: { value } }) => updatePassword(value)}
                    required
                    minLength={8}
                    pattern="((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{8,25})"
                />
                <Feedback type="invalid" tooltip>
                    <p>
                        Your password must be a minimum of 8 characters, contain at least 1 uppercase character, at least one
                        lowercase character and at least one symbol.
                    </p>
                </Feedback>
            </FloatingLabel>
            <Text data-testid="passwordVisibility" className="mb-3" onClick={togglePasswordVisibility}>
                {passwordVisible ? <i className="bi bi-eye" /> : <i className="bi bi-eye-fill" />}
            </Text>
        </InputGroup>
    );
};

export { Password };
