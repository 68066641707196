import { useState } from "react";
import {
    ConfirmForgotPasswordRequest,
    ForgotPasswordRequest,
    GlobalSignOutRequest,
    RespondToAuthChallengeRequest,
} from "aws-sdk/clients/cognitoidentityserviceprovider";
import * as jwt from "react-jwt";
import { LoginProps, ResetProps, Workflow } from "./types";
import { useConfigContext } from "../context/config-context";
import { useAuthContext } from "../context/auth-context";
import { UserAuthenticated } from "../context/types";

const useAuthentication = () => {
    const {
        auth: { clientId, flow },
        defaultLandingPage,
    } = useConfigContext();
    const [workflow, updateWorkflow] = useState<Workflow>(defaultLandingPage as Workflow);
    const {
        cognitoServiceProvider,
        updateAuthStatus,
        updateTokenStore,
        tokenStore,
        updateRefreshTokenCookie,
        updateEmail,
        email,
    } = useAuthContext();

    const [ session, updateSession ] = useState("");

    const { accessToken } = tokenStore;

    const getEmailFromJWT = (token: string) => {
        const decodedToken: any = jwt.decodeToken(token);
        return decodedToken.email;
    };

    const login = async ({ password }: LoginProps) => {
        const loginParams = {
            AuthFlow: flow,
            ClientId: clientId,
            AuthParameters: {
                USERNAME: email,
                PASSWORD: password,
            },
        };
        await new Promise((resolve, reject) => {
            const handleResult = (err: any, data: any) => {
                if (err) {
                    reject(err);
                } else {
                    if (data.AuthenticationResult) {
                        const {
                            AuthenticationResult: { IdToken, RefreshToken, AccessToken },
                        } = data;
                        updateTokenStore({
                            accessToken: AccessToken,
                            idToken: IdToken,
                            refreshToken: RefreshToken,
                        });
                        updateRefreshTokenCookie(RefreshToken);
                        updateAuthStatus(UserAuthenticated.YES);
                        updateEmail(getEmailFromJWT(IdToken));
                    }
                    else if(data.ChallengeName && data.ChallengeName === "NEW_PASSWORD_REQUIRED") {
                        updateSession(data.Session)
                        updateWorkflow("change");
                    }
                    resolve(data);
                }
            };
            try {
                cognitoServiceProvider.initiateAuth(loginParams, handleResult);
            } catch (e) {
                console.log(e);
            }
        });
    };

    const refreshLogin = async () => {
        function getCookie(name: string): string | null {
            const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
            return match ? match[2] : null;
        }
        const refreshToken = getCookie("refreshToken");

        if (refreshToken) {
            const refreshParams = {
                AuthFlow: "REFRESH_TOKEN_AUTH",
                ClientId: clientId,
                AuthParameters: {
                    REFRESH_TOKEN: refreshToken,
                },
            };
            await new Promise(resolve => {
                const handleResult = (err: any, data: any) => {
                    if (err) {
                        updateAuthStatus(UserAuthenticated.NO);
                    } else {
                        if (data.AuthenticationResult) {
                            const {
                                AuthenticationResult: { IdToken, AccessToken },
                            } = data;
                            updateTokenStore({ ...tokenStore, accessToken: AccessToken, idToken: IdToken });
                            updateAuthStatus(UserAuthenticated.YES);
                            updateEmail(getEmailFromJWT(IdToken));
                        }
                        resolve(data);
                    }
                };
                try {
                    cognitoServiceProvider.initiateAuth(refreshParams, handleResult);
                } catch (e) {
                    updateAuthStatus(UserAuthenticated.NO);
                    console.log(e);
                }
            });
        } else {
            updateAuthStatus(UserAuthenticated.NO);
        }
    };

    const recover = async () => {
        const forgotPasswordParams: ForgotPasswordRequest = {
            ClientId: clientId,
            Username: email,
        };
        await new Promise((resolve, reject) => {
            cognitoServiceProvider.forgotPassword(forgotPasswordParams, (err, data) => {
                if (err) {
                    reject(err);
                } else {
                    updateWorkflow("reset");
                    resolve(data);
                }
            });
        });
    };

    const reset = async ({ password, confirmationCode }: ResetProps) => {
        const confirmForgotPasswordParams: ConfirmForgotPasswordRequest = {
            ClientId: clientId,
            Username: email,
            ConfirmationCode: confirmationCode,
            Password: password,
        };
        await new Promise((resolve, reject) => {
            cognitoServiceProvider.confirmForgotPassword(confirmForgotPasswordParams, (err, data) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(data);
                    updateWorkflow("login");
                }
            });
        });
    };

    const logout = async () => {
        const logoutPasswordParams: GlobalSignOutRequest = {
            AccessToken: accessToken,
        };
        await new Promise((resolve, reject) => {
            cognitoServiceProvider.globalSignOut(logoutPasswordParams, (err, data) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(data);
                    updateAuthStatus(UserAuthenticated.NO);
                }
            });
        });
    };

    const change = async ({email, password} : LoginProps) => {
        const changePasswordParams: RespondToAuthChallengeRequest = {
            ClientId: clientId,
            ChallengeName: "NEW_PASSWORD_REQUIRED",
            ChallengeResponses: {"NEW_PASSWORD": password, "USERNAME": email},
            Session: session
        };
        await new Promise((resolve, reject) => {
            cognitoServiceProvider.respondToAuthChallenge(changePasswordParams, (err: any, data: any) => {
                if (err) {
                    reject(err);
                } else {
                    if (data.AuthenticationResult) {
                        const {
                            AuthenticationResult: { IdToken, RefreshToken, AccessToken },
                        } = data;
                        updateTokenStore({
                            accessToken: AccessToken,
                            idToken: IdToken,
                            refreshToken: RefreshToken,
                        });
                        updateRefreshTokenCookie(RefreshToken);
                        updateAuthStatus(UserAuthenticated.YES);
                        updateEmail(getEmailFromJWT(IdToken));
                    }
                    resolve(data);
                    updateAuthStatus(UserAuthenticated.YES);
                }
            });
        });
    }

    return {
        login,
        refreshLogin,
        recover,
        reset,
        change,
        email,
        updateEmail,
        workflow,
        updateWorkflow,
        logout,
    };
};

export { useAuthentication };
